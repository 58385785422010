/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable max-len */
import { ComponentType, useEffect, useState } from 'react';
import { FacebookProvider } from 'react-facebook';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { StyleProvider } from '@ant-design/cssinjs';
import { GoogleOAuthProvider } from '@react-oauth/google';

import MainLayout from 'src/components/Layout/MainLayout';
import XellarPopupConfirmation from 'src/components/Xellar/PopupConfirmation';
import { Web3ContextProvider } from 'src/context/web3';
import useAuthReducer from 'src/hooks/reducers/useAuthReducer';
import { wrapper } from 'src/redux/store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/styles/index.less';

export type NextPageWithLayout = NextPage & {
	getLayout?: ComponentType<any>;
	layoutProps?: any;
};

export let noIndexNoFollow = { noindex: false, nofollow: false };
if (process.env.NEXT_PUBLIC_FLAVOR !== 'MAINNET') {
	noIndexNoFollow = { noindex: true, nofollow: true };
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const AppRoot = ({ Component, pageProps }: AppPropsWithLayout) => {
	const router = useRouter();
	const { user } = useAuthReducer();

	const [queryClient] = useState(
		() => new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 } } }),
	);

	const Layout = Component.getLayout ?? MainLayout;
	const layoutProps = Component.layoutProps;

	useEffect(() => {
		if (!router.isReady) return;

		if (!user && pageProps?.protectedRoute) {
			router.push('/');
		}
	}, [JSON.stringify(user), router.isReady]);

	const url = router.pathname;

	return (
		<QueryClientProvider client={queryClient}>
			<Hydrate state={pageProps.dehydratedState}>
				<Web3ContextProvider>
					<GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}>
						<FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string}>
							<StyleProvider layer>
								<Layout {...pageProps} {...layoutProps} router={router}>
									<NextSeo
										{...noIndexNoFollow}
										canonical={process.env.NEXT_PUBLIC_WEB_URL + url}
										title="CREOPLAY"
										description="creoplay.app"
									/>
									<Head>
										<meta
											name="viewport"
											content="width=device-width, minimum-scale=1, maximum-scale=3, initial-scale=1, shrink-to-fit=no, height=device-height, viewport-fit=cover"
										/>
										<script
											async
											src="https://www.googletagmanager.com/gtag/js?id=G-DC9VYC06F1"
										></script>
										<script>
											{`
												window.dataLayer = window.dataLayer || [];
												function gtag(){dataLayer.push(arguments);}
												gtag('js', new Date());
												gtag('config', 'G-DC9VYC06F1');
											`}
										</script>
										<script id="gtm" {...({ strategy: 'afterInteractive' } as any)}>
											{`
												(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
												new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
												j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
												'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
												})(window,document,'script','dataLayer','GTM-TJW8466H');
											`}
										</script>
									</Head>
									<XellarPopupConfirmation />
									<Component {...pageProps} router={router} />
								</Layout>
							</StyleProvider>
						</FacebookProvider>
					</GoogleOAuthProvider>
				</Web3ContextProvider>
			</Hydrate>
		</QueryClientProvider>
	);
};

export default wrapper.withRedux(AppRoot);
